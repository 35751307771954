<template>
    <div class="referrer">
        <!--导航-->
        <van-nav-bar :title="navTitle"  left-arrow @click-left="leftReturn" fixed/>
<!--        <rxNavBar title="推荐人"  @click-left="leftReturn" :backProxy="true"></rxNavBar>-->
        <div class="communityDiv">
            <!--搜索栏-->
            <div class="searchDiv">
                <van-search  placeholder="请输入搜索关键词" v-model="referrerText" size="large" @search="initData()"/>
            </div>
        </div>
        <div class="likeShowDiv">
            <ul >
                <li  @click="selectOption(item)"  class="likeShowLi" v-for="item in referrer"  :label="item.userName + '-' + item.mobile">
                    <!--头像-->
                    <img class="profile-photo" :src="item.path ? item.path : profilePhoto" v-if="type!=7">

                    <div class="staff-info" v-if="type!=7">
                        <!--姓名-->
                        <div class="staff-name" style="font-size: 14px;margin-bottom: 5px">{{item.userName||item.staffName}}</div>
                        <!--电话-->
                        <div style="font-size: 12px">{{item.mobileStr}}</div>

                        <div class="staff-department" style="font-size: 14px;">{{item.departmentName}}</div>
                    </div>
                    <div class="staff-info" v-if="type==7">
                        <!--姓名-->
                        <div class="staff-name">{{item.subBankName}}</div>

                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { NavBar,search} from 'vant';
    import {
        queryStaffList,
        seeWithUserList,
        getUserList,
        queryStaffPotentialUser,
        findRecommendUser,
        queryRentTenant,
        querySubBank
    } from "../../../getData/getData";
    import {getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
    import eventBus from "../../../components/rongxun/rx-navBar/envbus.js"
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import util from "../../contract/util";
    export default {
        components: {
            [NavBar .name]:NavBar,
            [search .name]:search,
        },
        data() {
            return {
                profilePhoto:require('../../../assets/images/default-headImg.jpg'),
                navTitle:'', //根据type 动态改变标题
                //推荐人输入框
                referrerText:'',
                referrerId:'',
                staffInfo:{},//员工信息
                //推荐人显示列表
                referrer:[
                ],
                userListInfoInfo:[],//
                //
                type : '',
                flagType: '',
                name:'',
                maintainInfo:{},
            }
        },
        watch:{
            // 如果 推荐人`referrerText` 发生改变，
            referrerText: function () {
                this.answer = 'Waiting for you to stop typing...'

            }
        },
        mounted() {
            //初始
            if(this.$route.query.type){
                this.type = this.$route.query.type
                this.flagType = this.$route.query.flagType
                // this.type = 1
                if(this.type=='1'){
                    this.queryStaffList()
                    this.navTitle = '签约人'
                }else if(this.type=='2'){
                    this.queryStaffList()
                    this.navTitle = '合作人'
                }else if(this.type=='3'){
                    this.navTitle='承租人'
                    // this.getUserList();
                }else if(this.type=='4'){
                    this.navTitle='承租人'
                    // this.remoteMobileSearch();
                }else if(this.type=='6'){
                    this.navTitle='维护人'
                    if(this.$route.query.name){
                       this.name=this.$route.query.name
                    }
                    // this.remoteMobileSearch();
                }else if(this.type=='7'){
                    this.navTitle='支行'
                    this.getSubBankList()
                }else {
                    // this.seeWithUserList();
                    this.navTitle = '推荐人'
                    if(this.$route.query.name){
                       this.name=this.$route.query.name
                    }
                }
            }
            //从投诉过来修改 标题
            if(this.$route.query.titleName){
                this.navTitle = this.$route.query.titleName
            }
        },
        methods: {
            //搜索用方法
            initData(){
                if(this.type=='1' || this.type=='2'||this.type=='6'){
                    this.queryStaffList()
                }else if(this.type=='3'){
                  let referrerText = this.referrerText;
                  if((this.checkNumber(referrerText) && referrerText.length > 10) || !this.checkNumber(referrerText)){
                    this.seeWithUserList();
                  }else{
                    responseUtil.alertErrMsg(this,"请输入完整手机号");
                  }
                }else if(this.type=='5'){
                    let referrerText = this.referrerText;
                    if((this.checkNumber(referrerText) && referrerText.length > 10) || !this.checkNumber(referrerText)){
                        this.findRecommendUser();
                    }else{
                        responseUtil.alertErrMsg(this,"请输入完整手机号");
                    }
                }else if(this.type=='7'){
                    let referrerText = this.referrerText;
                    this.getSubBankList()

                }
                else {
                  let referrerText = this.referrerText;
                  if((this.checkNumber(referrerText) && referrerText.length > 10) || !this.checkNumber(referrerText)){
                    this.seeWithUserList();
                  }else{
                    responseUtil.alertErrMsg(this,"请输入完整手机号");
                  }
                }
            },

            //验证字符串是否是数字
            checkNumber(theObj) {
                var reg = /^[0-9]+.?[0-9]*$/;
                if (reg.test(theObj)) {
                    return true;
                }
                return false;
            },
            //退回潜房  or 新增修改合同
            leftReturn(){
                if(this.type==1){   //新增合同-签约人
                    let signPersonInfo = this.staffInfo
                    let staffInfo = {
                        signPersonInfo:signPersonInfo,
                    }
                    eventBus.$emit('referrer',staffInfo);
                }else if(this.type==2){  //新增合同-合作人
                    let cooperationPersonInfo = this.staffInfo
                    let staffInfo = {
                        cooperationPersonInfo:cooperationPersonInfo,
                    }
                    eventBus.$emit('referrer',staffInfo);
                }else if(this.type==3 || this.type==4){
                    let userListInfo = this.userListInfoInfo
                    let staffInfo = {
                        userListInfo:userListInfo,
                    }
                    eventBus.$emit('referrer',staffInfo);
                }else if(this.type==5){

                    let userListInfo = this.userListInfoInfo
                    let staffInfo = {
                        userListInfo:this.userListInfoInfo,
                    }
                    this.userListInfoInfo.name=this.name
                    eventBus.$emit('referrer',this.userListInfoInfo);
                }else if(this.type==6){
                    this.maintainInfo.name=this.name
                    eventBus.$emit('referrer',this.maintainInfo);
                }else if(this.type==7){
                    eventBus.$emit('referrer',this.maintainInfo);
                }else{
                    let params = {
                        referrerId:this.referrerId,
                        referrerName:this.referrerText,
                    }
                    //  //传递一个map，choiceHospital是key，hospital是value
                    eventBus.$emit('referrer',params);
                }

                //调用router回退页面
                this.$router.go(-1);
            },
            //查询支行
            getSubBankList(){
                let that = this;
                let initData = {};
                initData.bankId = this.$route.query.bankId
                initData.cityCode = this.$route.query.cityCode
                initData.subBankName=that.referrerText
                if(!initData.bankId||!initData.cityCode){
                    return
                }
                querySubBank(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                          that.referrer=response.data.data.data
                    })
                })
            },
            //推荐人初始化方法
            seeWithUserList(){
                let that = this;
                let initData = {};
                initData.user_id = getStaffId()
                initData.userMobile = that.referrerText
                queryStaffPotentialUser(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.referrer = response.data.data.data
                        for (var index in that.referrer) {
                            that.$set(that.referrer[index],'mobileStr',that.geTel(that.referrer[index].mobile) )
                        }
                    })
                })

            },
            //推荐人初始化方法
            findRecommendUser(){
                let that = this;
                let initData = {};
                initData.searchName = that.referrerText
                initData.user_id=getStaffId()
                //queryRentTenant
                findRecommendUser(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.referrer = response.data.data.data
                        for (var index in that.referrer) {
                            that.$set(that.referrer[index],'mobileStr',that.geTel(that.referrer[index].mobile) )
                        }
                    })
                })

            },
            //查询员工列表
            queryStaffList(){

                let that = this
                let initData = {}
                initData.searchName = that.referrerText
                queryStaffList(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.referrer = response.data.data.data
                        for (var index in that.referrer) {
                           that.$set(that.referrer[index],'mobileStr',that.geTel(that.referrer[index].mobile) )
                        }
                    })
                })
            },

            //查询承租人列表(预定合同用)
            getUserList(){
                let that = this
                let initData = {}
                initData.searchInfo = that.referrerText
                getUserList(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.referrer = response.data.data.data
                        for (var index in that.referrer) {
                            that.$set(that.referrer[index],'mobileStr',that.geTel(that.referrer[index].mobile) )
                        }
                    })
                })
            },

            //查询承租人列表(租客合同用)
            remoteMobileSearch: function () {
                var that = this;
                let queryData = {};
                queryData.user_id = getStaffId();
                queryData.flagType = that.flagType;
                queryStaffPotentialUser(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.referrer = response.data.data.data
                        for (var index in that.referrer) {
                            that.$set(that.referrer[index],'mobileStr',that.geTel(that.referrer[index].mobile) )
                        }
                    })
                })
            },

            // 手机号隐藏四位
            geTel(tel) {
                var reg = /^(\d{3})\d{4}(\d{4})$/
                return tel.replace(reg, '$1****$2')
            },

            //select 选择推荐人
            selectOption(item){
                let that = this;
                if(that.type == 1 || that.type == 2){
                    let staffId = item.id;
                    that.initStaffData(staffId);
                }else if (that.type==3 || that.type==4){
                    that.userListInfoInfo=item
                    that.leftReturn()
                }else if(that.type==5){
                    that.userListInfoInfo=item
                    that.leftReturn()
                }else if(that.type==6){
                    that.maintainInfo=item
                    that.leftReturn()
                }else if(that.type==7){
                    that.maintainInfo=item
                    that.leftReturn()
                }else{
                    that.referrerText = (item.userName || item.staffName) + '-' + item.mobileStr;
                    that.referrerId = item.id;
                    that.leftReturn();
                }


            },
            //新增合同使用，将查到的关于员工的部门等信息
            initStaffData(staffId) {
                var that = this;
                let initData = {};
                initData.id = staffId
                queryStaffList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.staffInfo = response.data.data.data[0]
                        that.leftReturn();
                    });
                });
            },


        },

      //路由跳转时销毁全局bus里的监听事件
        beforeDestroy() {
          eventBus.$off('referrer')
        }
    }
</script>

<style lang="less" scoped >

</style>
